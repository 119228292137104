import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import {
    measurePage,
    mainSection,
    measure1,
    measure2,
    measure3,
    measureLast,
    measureWrapper,
    measureIntro,
    icon,
    heading,
    aboutMeasure,
    aboutMeasureText,
    measureImg,
    tableWrapper,
    sizesWrapper,
    sizesImage,
    sizesEur,
    sizesInt,
    title,
    upperMeasures,
    measureFigure,
    tapeMeasure,
    tapeSection,
    measureContainer,
    measureDot,
    measureHeading,
    measureDescription,
} from "./how-to-measure.module.scss";
import Porady from "../../assets/images/svg/porady.svg";
import TapeMeasure from "../../assets/images/svg/tapeMeasure.svg";
import Dot from "../../assets/images/svg/dot.svg";
import NeckSize from "../../assets/images/svg/neckSize.svg";
import ChestSize from "../../assets/images/svg/chestSize.svg";
import ArmLength from "../../assets/images/svg/armLength.svg";
import BodyLength from "../../assets/images/svg/bodyLength.svg";
import BackLength from "../../assets/images/svg/backLength.svg";
import BackWidth from "../../assets/images/svg/backWidth.svg";
import WaistSize from "../../assets/images/svg/waistSize.svg";
import BeltSize from "../../assets/images/svg/beltSize.svg";
import MidsectionSize from "../../assets/images/svg/midsectionSize.svg";
import HipsSize from "../../assets/images/svg/hipsSize.svg";
import LegLength from "../../assets/images/svg/legLength.svg";
import FeetSize from "../../assets/images/svg/feetSize.svg";

import PantsBeltSize from "../../assets/images/svg/pantsBeltSize.svg";
import PantsHipsSize from "../../assets/images/svg/pantsHipsSize.svg";
import PantsMidsectionSize from "../../assets/images/svg/pantsMidsectionSize.svg";
import PantsLegLength from "../../assets/images/svg/pantsLegLength.svg";
import UpperChestSize from "../../assets/images/svg/upperChestSize.svg";
import UpperShouldersWidth from "../../assets/images/svg/upperShouldersWidth.svg";
import UpperBeltSize from "../../assets/images/svg/upperBeltSize.svg";
import UpperWaistSize from "../../assets/images/svg/upperWaistSize.svg";
import UpperLength from "../../assets/images/svg/upperLength.svg";

import usePageMetadata from "../../hooks/use-page-metadata";
import { IPageBase } from "../../models/page-base.model";
import { ISite } from "../../models/site.model";

import MainLayout from "../../layouts/main-layout";
import SectionWrapper from "../molecules/section-wrapper";
import Section from "../molecules/section";
import Title from "../atoms/title";
import MeasureSection from "../organisms/measure-section";
import FooterStylizeSection from "../molecules/footer-stylize-section";
import MainSection from "../organisms/sections/common/main-section";
import TwoBoxSection from "../organisms/sections/common/two-box-section";
import BackgroundBoxSection from "../organisms/sections/common/background-box-section";
import SummarySection from "../organisms/sections/how-measure/summary-section";
import BackgroundTextSection from "../organisms/sections/common/background-text-section";
import Header from "../organisms/headers/header";

interface IMeasureProps extends IPageBase {
    readonly data: { site: ISite } & { [key: string]: ImageDataLike };
}

const HowToMeasure: React.FC<IMeasureProps> = ({ data, pageContext }) => {
    const { site, coverImg } = data;
    const measureImage = getImage(coverImg);

    const { pageTitle, defaultStructuredData } = usePageMetadata(pageContext, site);

    const measurementsUpper = [
        {
            id: 10,
            image: <NeckSize />,
            title: "Obwód szyi",
            content:
                "Poprowadź centymetr wokół nasady szyi. Powinien on przylegać do ciała na całej powierzchni, nie wywołując przy tym dyskomfortu.",
        },
        {
            id: 11,
            image: <ChestSize />,
            title: "Obwód klatki piersiowej",
            content:
                "Mężczyźni prowadzą centymetr pod pachami, przy zgięciu ramion. U pań taśmę należy owinąć wokół biustu, w najszerszej części tułowia centymetr powinien dość ciasno przylegać do ciała i być ułożony horyzontalnie do podłogi.",
        },
        {
            id: 12,
            image: <ArmLength />,
            title: "Długość rękawa",
            content:
                "Samemu jest trudno zdjąć w tym miejscu miarę, więc trzeba poprosić kogoś o pomoc. Gdy mamy już pomocnika, stajemy prosto i luźno opuszczamy ręce. Druga osoba powinna przyłożyć centymetr do kości ramiennej (miejsca, gdzie zwykle wszyty jest rękaw) i poprowadzić go aż do nasady kciuka. Inny sposób na zmierzenie długości rękawa to zdjęcie miary z dobrze dopasowanej koszuli, którą mamy już w domu. Rękaw mierzymy od szwu naramiennego do końca rękawa (włącznie z mankietem w przypadku koszuli).",
        },
        {
            id: 13,
            image: <BodyLength />,
            title: "Długość tułowia",
            content:
                "Zmierz długość od kości na karku do wysokości talii.  Prowadź centymetr wzdłuż piersi, tak by ująć w pomiarze jej wypukłość.",
        },
        {
            id: 14,
            image: <BackLength />,
            title: "Długość pleców",
            content:
                "Zrób pomiar w miejscu analogicznym do tułowia w tym przypadku centymetr musi przebiegać przez wypukłość łopatki.",
        },
        {
            id: 15,
            image: <BackWidth />,
            title: "Szerokość pleców",
            content: "Poprowadź miarkę między zgięciami obu ramion, na wysokości łopatek.",
        },
        {
            id: 16,
            image: <WaistSize />,
            title: "Obwód talii",
            content:
                "Zmierz obwód tułowia w najwęższym miejscu. Taśma powinna być dopasowana, ale nie może ściskać mocno brzucha.",
        },
        {
            id: 17,
            image: <BeltSize />,
            title: "Obwód pasa",
            content:
                "Owiń centymetr wokół brzucha, na wysokości pępka. Trzymaj miarkę luźno, nie napinaj jej mocno – jest to szczególnie istotne, gdy wybierasz rozmiar ubrań z nieelastycznych materiałów. Pomiaru najlepiej dokonaj rano, na wydechu, nigdy po obfitym posiłku i nie wciągając brzucha. Centymetr prowadzimy horyzontalnie do podłoża.",
        },
        {
            id: 18,
            image: <MidsectionSize />,
            title: "Wysokość stanu",
            content:
                "„Stan” - to długość od kroku spodni do pępka. Miara ta pomoże Ci nie tylko dobrać rozmiar spodni, ale i określić ich fason, co czasem bywa skomplikowane na podstawie jedynie zdjęcia. Im krótszy stan, tym niżej wypada pas spodni.",
        },
        {
            id: 19,
            image: <HipsSize />,
            title: "Obwód bioder",
            content:
                "Poprowadź centymetr wokół bioder i pośladków tak, by objąć je w najszerszym miejscu. Podobnie, jak w przypadku pasa, nie napinaj miarki zbyt mocno.",
        },
        {
            id: 20,
            image: <LegLength />,
            title: " Długość nogawki",
            content:
                "O ile w tabeli rozmiarów sprzedawcy nie zaznaczono inaczej, wartość ta dotyczy wewnętrznej długości nogawki– od kroku do nasady stopy lub górnej krawędzi podeszwy buta, który planujesz najczęściej nosić z tymi spodniami. Natomiast jeśli tabela rozmiarów podaje zewnętrzną długość nogawki, należy zmierzyć nogę z boku od pasa.",
        },
        {
            id: 21,
            image: <FeetSize />,
            title: "Długość Stopy",
            content:
                "Pomiarów dokonuj na stojąco, od początku pięty do końca palców. Jeśli Twoje stopy różnią się długością, wybierz rozmiar odpowiadający dłuższej z nich. Aby zyskać komfort, postaw na buty z zapasem około 0,5 cm.",
        },
    ];

    const measurementsLower = [
        {
            id: 22,
            image: <PantsBeltSize />,
            title: "obwód pasa",
            content:
                "Nawet, jeśli materiał pasa układa się w łuk, poprowadź centymetr prosto między bocznymi krawędziami materiału.",
        },
        {
            id: 23,
            image: <PantsHipsSize />,
            title: "obwód bioder",
            content: "Poprowadź centymetr prosto w najszerszym miejscu bioder.",
        },
        {
            id: 24,
            image: <PantsMidsectionSize />,
            title: "wysokość stanu",
            content: "Poprowadź centymetr pionowo od kroku do górnej krawędzi spodni.",
        },
        {
            id: 25,
            image: <PantsLegLength />,
            title: "długość nogawki",
            content:
                "Postępuj tak samo, jak przy pomiarze sylwetki – sprawdź, jaką długość (zewnętrzną/wewnętrzną) podaje tabela rozmiarów, a następnie zmierz długość zewnętrznego lub wewnętrznego szwu swoich spodni.",
        },
        {
            id: 26,
            image: <UpperChestSize />,
            title: "szerokość klatki piersiowej",
            content:
                "Poprowadź centymetr od pachy do pachy, w miejscu złączenia szwów korpusu i rękawa",
        },
        {
            id: 27,
            image: <UpperShouldersWidth />,
            title: "szerokość barków/ramion",
            content: "Poprowadź centymetr u góry ubrania, między szwami rękawów.",
        },
        {
            id: 28,
            image: <UpperBeltSize />,
            title: "szerokość pasa",
            content:
                "Zacznij od przymierzenia ubrania i zaznaczenia punktu, w którym wypada pępek. Teraz rozłóż odzież na płasko i zmierz szerokość w wyznaczonym miejscu.",
        },
        {
            id: 29,
            image: <UpperWaistSize />,
            title: "szerokość w talii",
            content:
                "Postępuj tak samo, jak w przypadku pasa, zaznaczając przy tym strefę największego zwężenia tułowia.",
        },
        {
            id: 30,
            image: <UpperLength />,
            title: "Długość całkowita",
            content: "Mierzymy od samej góry ściągacza przy szyi, po sam dół koszulki.",
        },
    ];

    return (
        <MainLayout
            className={measurePage}
            headerColor={"white"}
            HeaderComponent={<Header color="white" hideLogo={true} />}
            SEOProps={{
                title: pageTitle,
                structuredData: defaultStructuredData,
            }}
        >
            <MainSection showButton={true}
                titleText={<div>Jak prawidłowo się mierzyć?</div>}
                text1="Internet daje nam ogromną możliwość wyboru stylizacji z dostawą pod same
                        drzwi. Jest to dla nas bardzo wygodna opcja, ale też budzi wiele
                        wątpliwości, jeśli chodzi o dobór odpowiednego rozmiaru. Jeśli Ty też
                        obawiasz się, że możesz źle dobrać swój rozmiar, przeczytaj nasze porady,
                        które mogą Ci pomóc nie popełnić błędu tej kwestii. Jest kilka zasad
                        mierzenia ubioru, które sprawią, że zamówiony przez Ciebie model, będzie
                        dobrze dopasowany do Twojej figury."
                className={mainSection}
            />
            <TwoBoxSection classNameLeftWrapper={measure1}
                title={"Liczby czy symbole?"}
                text={<div>Często oznaczenie samego rozmiaru nie wystarczy. Producenci szyją ubrania na
                    odmienne rynki i różne typy figur, dlatego na przykład rozmiar M w różnych
                    markach może odpowiadać zarówno standardowemu S, jak i być zbliżone do
                    rozmiaru L.
                    <br />
                    Bardzo ważnym punktem odniesienia jest tabela rozmiarów, zaznaczona na
                    karcie produktu, w opisie sprzedawcy. Zawiera ona wymiary odzieży podane w
                    centymetrach. Wymiary danego modelu, musisz porównać z wymiarami własnej
                    sylwetki. Jednak nie wystarczy zmierzyć tylko siebie, ale i najczęściej
                    noszone przez nas ubrania. Ten sposób ułatwi nam, wyobrażenie jak zamówiona
                    rzecz będzie układać się na ciele.
                    <br />
                    <br /> Aby dokonać dokładnego pomiaru ciała, wystarczy:
                    <br />
                    • mieć miękką, elastyczną miarę krawiecką (tzw. centymetrem), który dobrze
                    przylega do ciała i nie odkształca się.
                    <br />
                    • dokładnie zdjąć wymiary (w tym celu warto poprosić o pomoc drugą osobę).
                    <br />• wiedzieć w jakim miejscu dokładnie się mierzyć.
                </div>}
                quoteText1={"\"Zaufaj Wróżce Stylistce i odkryj swój unikalny styl.\""}
                quoteSubtext1={"Fashion Fairy"}/>
            
            <BackgroundBoxSection className={measure2}/>
            <SectionWrapper className={measureFigure}  style={{ padding: "4em 0 0", textAlign: "center" }}>
                <Section column={"narrow"} columnTablet={"narrow"} columnPhone={"narrow"}>
                    <h2 className={measureHeading}>Zacznijmy od góry:</h2>
                </Section>
            </SectionWrapper>
            <SectionWrapper className={measureFigure} style={{ padding: "0 0 4em" }}>
                <Section
                    column={"regular"}
                    columnTablet={"regular"}
                    columnPhone={"regular"}
                    className={upperMeasures}
                >
                    {measurementsUpper.map((elements) => {
                        return <MeasureSection key={elements.id} {...elements} />;
                    })}
                </Section>
            </SectionWrapper>
            {/*MIEJSCE na zaczynamy od góry */}
            
            <TwoBoxSection classNameLeftWrapper={measure3}
                isReverse={true}
                title={"Jak prawidłowo mierzyć ubranie?"}
                text={<div>Zapnij wszystkie guziki i zamki błyskawiczne w mierzonym ubraniu. Następnie
                    rozłóż je na płaskim podłożu, wygładzając przy tym wszystkie załamania i
                    fałdy ubioru.
                </div>}
                quoteText1={"\"Moda to sztuka, a Ty jesteś płótnem.\""}
                quoteSubtext1={"Anonimowy"} />
            <SectionWrapper className={measureFigure} style={{ padding: "4em 0 0", textAlign: "center" }}>
                <Section column={"narrow"} columnTablet={"narrow"} columnPhone={"narrow"}>
                    <h2 className={measureHeading}>Wymiary ubrań:</h2>
                </Section>
            </SectionWrapper>
            <SectionWrapper className={measureFigure} style={{ padding: "0" }}>
                <Section className={upperMeasures}>
                    {measurementsLower.map((elements) => {
                        return <MeasureSection key={elements.id} {...elements} />;
                    })}
                </Section>
            </SectionWrapper>

            <SummarySection ></SummarySection>
            <BackgroundTextSection className={measureLast}></BackgroundTextSection>
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        coverImg: file(relativePath: { eq: "how-to.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        logo: file(relativePath: { eq: "emilka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        site {
            ...siteFields
        }
    }
`;

export default HowToMeasure;
